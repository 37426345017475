import { useState, useRef, useEffect } from "react";
import MarkdownIt from "markdown-it";
import AudioComponent from "../partials/audio";
import MicroComponent from "../partials/micro";
import AudioSelectable from "../partials/audioSelectable";
import { Textfit } from "react-textfit";

const markdownParser = new MarkdownIt({
    html: true,
});

const randomRotationFunction = (maxRotation = 6) => {
    let randomRotate = Math.floor(Math.random() * maxRotation) + 1;
    randomRotate *= Math.round(Math.random()) ? 1 : -1;
    return randomRotate;
};

const hex2rgba = (hex, alpha = 1) => {
    const [r, g, b] = hex.match(/\w\w/g).map((x) => parseInt(x, 16));
    return `rgba(${r},${g},${b},${alpha})`;
};

const randomShadeColour = (hex) => {
    const randomAlpha = Math.floor(Math.random() * (100 - 10 + 1)) + 10;
    const rgba = hex2rgba(hex, randomAlpha / 100);
    return rgba;
};

const Mot = ({
    item,
    className,
    index,
    motSelected = [],
    onClickMot = () => {},
    selectable = false,
    audioPlacement = "default",
    grupIndex = null,
    isWrong = false,
    textType = "text-in",
    selectAudio = false,
    size = "large",
    bgColor = false,
    typeBgColor = null,
    maxRotation = 6,
    grupSelectable = false,
}) => {
    const motRef = useRef(null);

    const scrollContainer = useRef(null);
    const [styles, setStyles] = useState({});
    const [showText, setShowText] = useState(false);
    const [showDescription, setShowDescription] = useState(false);
    const [audioSelected, setAudioSelected] = useState([]);
    const [wrongAudioSelected, setWrongAudioSelected] = useState(null);
    const [randomRotation, setRandomRotation] = useState(
        randomRotationFunction(maxRotation)
    );
    const [audioRecording, setAudioRecording] = useState(false);
    const [audioPlaying, setAudioPlaying] = useState(false);

    useEffect(() => {
        initStyles();
    }, []);

    const initStyles = () => {
        const styles = {};
        if (item.image_small) {
            styles.backgroundImage = `url("/storage/${item.image_small}")`;
        }
        if (bgColor) {
            styles.backgroundColor = bgColor;
        }
        if (typeBgColor === "shadows" && bgColor) {
            styles.backgroundColor = randomShadeColour(bgColor);
        }
        setStyles(styles);
    };

    const scrollUp = () => {
        const currentPosition = scrollContainer.current.scrollTop;
        scrollContainer.current.scroll({
            top: currentPosition !== 0 ? currentPosition - 60 : 0,
            behavior: "smooth",
        });
    };

    const scrollDown = () => {
        const currentPosition = scrollContainer.current.scrollTop;
        scrollContainer.current.scroll({
            top: currentPosition + 60,
            behavior: "smooth",
        });
    };

    const onAudioClick = (aindex) => {
        setWrongAudioSelected(null);
        const answers = item.answers_array.map((a) => parseInt(a));
        if (audioSelected.includes(aindex)) {
            const filteredItems = audioSelected.filter((a, i) => i !== aindex);
            setAudioSelected(filteredItems);
        } else {
            if (answers.includes(aindex + 1)) {
                onClickMot(index);
                setAudioSelected([...audioSelected, aindex]);
            } else {
                setWrongAudioSelected(aindex);
                setTimeout(() => {
                    setWrongAudioSelected(null);
                }, 2000);
            }
        }
    };

    const handleMouseEnter = () => {
        motRef.current.style.zIndex = 10;
        if (textType === "text-out") {
            setShowText(true);
        }
    };

    const handleMouseLeave = () => {
        motRef.current.style.zIndex = "inherit";
        if (textType === "text-out") {
            setShowText(false);
        }
    };

    return (
        <div className={className}>
            <div
                className={`fc-mot-item size-${size}`}
                ref={motRef}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                style={{ transform: "rotate(" + randomRotation + "deg)" }}
            >
                <div
                    className={`fc-mot-image ${
                        item.image_small ? "has-image" : "no-image"
                    } ${selectable ? "mot-selectable" : ""} ${
                        motSelected.includes(index) ? "selected" : ""
                    } ${isWrong ? "is-wrong" : ""}`}
                    style={styles}
                    onClick={() =>
                        onClickMot(grupIndex !== null ? grupIndex : index)
                    }
                >
                    {["text-in", "text-middle", "text-middle-scroll"].includes(
                        textType
                    ) && (
                        <div
                            className={`fc-mot-title w-100 ${textType}`}
                            ref={scrollContainer}
                        >
                            {!showDescription && !item.hide_text && (
                                <>
                                    {textType === "text-in" && (
                                        <Textfit
                                            mode={
                                                size !== "small"
                                                    ? "single"
                                                    : "multi"
                                            }
                                            max={size == "small" ? 35 : 90}
                                            min={40}
                                        >
                                            <span
                                                style={{ lineHeight: 1 }}
                                                className={`d-block w-100 fc-mot-title-text ${item.text_color}`}
                                                dangerouslySetInnerHTML={{
                                                    __html: markdownParser.render(
                                                        item.text
                                                    ),
                                                }}
                                            />
                                        </Textfit>
                                    )}
                                    {textType !== "text-in" && (
                                        <span
                                            className={`d-block w-100 fc-mot-title-text ${item.text_color}`}
                                            dangerouslySetInnerHTML={{
                                                __html: markdownParser.render(
                                                    item.text
                                                ),
                                            }}
                                        />
                                    )}
                                </>
                            )}
                            {showDescription && (
                                <span
                                    className={`fc-mot-title-description ${item.text_color}`}
                                    dangerouslySetInnerHTML={{
                                        __html: markdownParser.render(
                                            item.description
                                        ),
                                    }}
                                />
                            )}
                        </div>
                    )}
                    {selectable && (
                        <div className={`${item.text_color} fc-mot-overlay`}>
                            {motSelected.includes(index) && (
                                <img
                                    src={`/images/${
                                        size === "small"
                                            ? "check-medium.png"
                                            : "check.svg"
                                    }`}
                                    alt="Correcte"
                                />
                            )}
                            {isWrong && (
                                <img
                                    src={`/images/${
                                        size === "small"
                                            ? "cross-medium.png"
                                            : "cross.png"
                                    }`}
                                    alt="Incorrecte"
                                />
                            )}
                        </div>
                    )}
                    {grupSelectable && (
                        <div className={`fc-grup-mot-overlay`} />
                    )}
                    <div className={`fc-title-overlay ${item.text_color}`} />
                    {!!item.description && textType === "text-middle" && (
                        <div
                            className="mc-mot-description-toggle"
                            onClick={() => setShowDescription(!showDescription)}
                        >
                            <img
                                src={`/images/${
                                    showDescription
                                        ? "icn-flecha-cerrar"
                                        : "info"
                                }.svg`}
                            />
                        </div>
                    )}
                    {textType === "text-middle-scroll" && (
                        <div className="fc-mot-scroll-arrows">
                            <span
                                className="up cursor-pointer cursor-pointer"
                                onClick={scrollUp}
                            >
                                <img src="/images/up.svg" alt="Per amunt" />
                            </span>
                            <span
                                onClick={scrollDown}
                                className="down cursor-pointer"
                            >
                                <img src="/images/down.svg" alt="Per avall" />
                            </span>
                        </div>
                    )}
                </div>
                {textType === "text-out" && (
                    <div className="fc-mot-text-out">
                        <p
                            className={`${
                                audioRecording || audioPlaying || showText
                                    ? "show"
                                    : "hide"
                            } pb-1`}
                            dangerouslySetInnerHTML={{
                                __html: markdownParser.render(item.text),
                            }}
                        />
                    </div>
                )}
                {textType === "text-middle-scroll" && !!item.description && (
                    <div className="fc-mot-out-description">
                        <p
                            dangerouslySetInnerHTML={{
                                __html: markdownParser.render(item.description),
                            }}
                        />
                    </div>
                )}
                {audioPlacement === "dragging" && (
                    <div className="fc-mot-bottom absolute-right">
                        <div
                            className="fc-mot-events"
                            onClick={() => setShowText(!showText)}
                        >
                            {!!item.audios_url && (
                                <AudioComponent
                                    overColor={bgColor}
                                    url={item.audios_url[0]}
                                />
                            )}
                        </div>
                    </div>
                )}
                {audioPlacement === "default" && (
                    <div className="fc-mot-bottom">
                        <div
                            className="fc-mot-events"
                            onClick={() => setShowText(!showText)}
                        >
                            {!!item.audios_url && (
                                <AudioComponent
                                    setAudioPlaying={setAudioPlaying}
                                    overColor={bgColor}
                                    url={item.audios_url[0]}
                                />
                            )}
                            {!!item.audios_url && !!item.recorder && (
                                <MicroComponent
                                    overColor={bgColor}
                                    audioRecordingCallback={setAudioRecording}
                                    url={item.audios_url[0]}
                                />
                            )}
                        </div>
                    </div>
                )}
                {audioPlacement === "right" && (
                    <div className="fc-mot-right">
                        <div className="fc-mot-events-right">
                            {!!item.audios_url && (
                                <AudioComponent
                                    overColor={bgColor}
                                    url={item.audios_url[0]}
                                />
                            )}
                        </div>
                    </div>
                )}
                {audioPlacement === "select-audio" && (
                    <div className="fc-mot-bottom fc-mot-bottom-select-audio">
                        {!!item.audios &&
                            item.audios.map((a, i) => (
                                <>
                                    <AudioSelectable
                                        overColor={bgColor}
                                        audio={a}
                                        index={i}
                                        isWrong={wrongAudioSelected === i}
                                        selected={audioSelected.includes(i)}
                                        onAudioClick={onAudioClick}
                                        key={a.id}
                                    />
                                    {i === 0 && (
                                        <div className="d-flex align-items-center justify-content-center fc-mot-icon-desigual">
                                            <img src="/images/desigual.svg" />
                                        </div>
                                    )}
                                </>
                            ))}
                    </div>
                )}
            </div>
        </div>
    );
};

export default Mot;
