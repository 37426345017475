
const Modal = ({
    visible, children, onClose = () => {
    }
}) => {

    if (!visible) {
        return null;
    }

    return (
        <div className="fc-modal">
            <div className="fc-modal-body">
                <div className="fc-modal-closer">
                    <a onClick={onClose}>
                        <img src="/images/icn-flecha-cerrar.svg" />
                    </a>
                </div>
                {children}
            </div>
        </div>
    )
}

export default Modal;