import Mot from "../items/mot";
import { memo, useState, useCallback } from "react";

const ModelParelles = ({ pantalla }) => {
    const Grup = ({ grup }) => {
        const [itemSelected, setItemSelected] = useState([]);
        const [wrongSelected, setWrongSelection] = useState(null);

        const MemoItem = memo(function MemoItem({ item, index }) {
            return (
                <Mot
                    item={item}
                    index={index}
                    onClickMot={onItemClick}
                    motSelected={itemSelected}
                    isWrong={wrongSelected === index}
                    selectable
                    size="small"
                    bgColor={pantalla.so.color}
                    audioPlacement="right"
                    className="py-2"
                />
            );
        });

        const onItemClick = useCallback((index) => {
            setWrongSelection(null);
            const answers = grup.answers_array.map((a) => parseInt(a));
            if (itemSelected.includes(index)) {
                setItemSelected((prev) => prev.filter((a, i) => i !== index));
            } else {
                if (answers.includes(index + 1)) {
                    setItemSelected((prev) => [...prev, index]);
                } else {
                    setWrongSelection(index);
                    setTimeout(() => {
                        setWrongSelection(null);
                    }, 2000);
                }
            }
        });

        return (
            <>
                <div className={`col-md-4 fc-grup mb-4`} key={grup.id}>
                    <div
                        className="fc-grup-bg"
                        style={{
                            borderColor: pantalla.so.color,
                        }}
                    />
                    {!!grup.items &&
                        grup.items.map((item, index) => (
                            <MemoItem key={item.id} item={item} index={index} />
                        ))}
                </div>
            </>
        );
    };

    return (
        <div className={"h-100 " + pantalla.tipus_machine_name}>
            {!!pantalla.grups && (
                <>
                    <div className="row justify-content-evenly">
                        {pantalla.grups.length > 1 &&
                            pantalla.grups.map((grup) => (
                                <Grup grup={grup} key={grup.id} />
                            ))}
                    </div>
                </>
            )}
        </div>
    );
};

export default ModelParelles;
