import { useState, useCallback, useEffect } from "react";
import Mot from "../items/mot";

const ModelSoCorrecte = ({ pantalla }) => {
    const correctedAnswers = !!pantalla.grups[0]
        ? pantalla.grups[0].answers_array.map((el) => parseInt(el) - 1)
        : [];
    const [itemSelected, setItemsSelected] = useState([]);

    useEffect(() => {
        window.localStorage.setItem(pantalla.id + "_isCorrect", 0);
    }, []);

    useEffect(() => {
        if (
            JSON.stringify(itemSelected.sort()) ===
            JSON.stringify(correctedAnswers.sort())
        ) {
            window.localStorage.setItem(pantalla.id + "_isCorrect", 1);
        }
    }, [itemSelected]);

    const onItemClick = useCallback((index) => {
        setItemsSelected((prev) =>
            prev.includes(index)
                ? prev.filter((x) => x !== index)
                : [...prev, index]
        );
    }, []);

    return (
        <div className={"h-100 " + pantalla.tipus_machine_name}>
            {!!pantalla.grups && (
                <>
                    {pantalla.grups.map((grup) => (
                        <div
                            className="row justify-content-center"
                            key={grup.id.toString()}
                        >
                            <div
                                className={
                                    "col-lg-8 row gy-2 justify-content-start fc-grup fc-grup-" +
                                    grup.id
                                }
                            >
                                {!!grup.items && (
                                    <>
                                        {grup.items.map((item, index) => (
                                            <Mot
                                                key={item.id.toString()}
                                                index={index}
                                                bgColor={pantalla.so.color}
                                                item={item}
                                                className={"col-md-6 mb-4"}
                                                audioPlacement="select-audio"
                                                onClickMot={onItemClick}
                                            />
                                        ))}
                                    </>
                                )}
                            </div>
                        </div>
                    ))}
                </>
            )}
        </div>
    );
};

export default ModelSoCorrecte;
