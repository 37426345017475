import {
    useState,
    useContext,
    useEffect,
    memo,
    useCallback,
    useRef,
} from "react";
import Mot from "../items/mot";

const ModelReconeixer = ({ pantalla, onScroll = () => {} }) => {
    const [itemSelected, setItemsSelected] = useState([]);

    const correctedAnswers = !!pantalla.grups[0]
        ? pantalla.grups[0].answers_array.map((el) => parseInt(el) - 1)
        : [];

    useEffect(() => {
        window.localStorage.setItem(pantalla.id + "_isCorrect", 0);
    }, []);

    useEffect(() => {
        if (
            JSON.stringify(itemSelected.sort()) ===
            JSON.stringify(correctedAnswers.sort())
        ) {
            window.localStorage.setItem(pantalla.id + "_isCorrect", 1);
        }
    }, [itemSelected]);

    const onItemClick = useCallback((index) => {
        setItemsSelected((prev) =>
            prev.includes(index)
                ? prev.filter((x) => x !== index)
                : [...prev, index]
        );
    }, []);

    const MemoItem = memo(function MemoItem({ item, index, itemSelected }) {
        return (
            <Mot
                index={index}
                item={item}
                selectable
                motSelected={itemSelected}
                onClickMot={onItemClick}
                bgColor={pantalla.so.color}
                className={"col-md-6 mb-2"}
            />
        );
    });

    return (
        <div className={"h-100 " + pantalla.tipus_machine_name}>
            {!!pantalla.grups && (
                <>
                    {pantalla.grups.map((grup) => (
                        <div
                            className="row justify-content-center"
                            key={grup.id.toString()}
                        >
                            <div
                                className={
                                    "col-lg-8 row gy-2 justify-content-start fc-grup fc-grup-" +
                                    grup.id
                                }
                            >
                                {!!grup.items && (
                                    <>
                                        {grup.items.map((item, index) => (
                                            <MemoItem
                                                key={item.id}
                                                index={index}
                                                item={item}
                                                itemSelected={itemSelected}
                                            />
                                        ))}
                                    </>
                                )}
                            </div>
                        </div>
                    ))}
                </>
            )}
        </div>
    );
};

export default ModelReconeixer;
