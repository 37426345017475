import React from "react";
import 'animate.css';

const Loader = ({visible}) =>{

    if(!visible){
        return null;
    }

    return (
        <div className="vh-100 vw-100 bg-light d-flex flex-column align-items-center justify-content-center position-absolute">
            <div className="text-center animate__animated animate__heartBeat"><h2>Carregant...</h2></div>
        </div>
    )
}

export default Loader;