import { useState, useLayoutEffect, memo, useCallback, useEffect } from "react";
import AudioSelectable from "../partials/audioSelectable";

const ModelSonsCorrectes = memo(function ModelSonsCorrectes({ pantalla }) {
    const correctedAnswers = !!pantalla.grups[0]
        ? pantalla.grups[0].answers_array.map((el) => parseInt(el) - 1)
        : [];
    const [itemSelected, setItemsSelected] = useState([]);
    const [leftColumn, setLeftColumn] = useState([]);
    const [rightColumn, setRightColumn] = useState([]);

    useEffect(() => {
        window.localStorage.setItem(pantalla.id + "_isCorrect", 0);
        setLeftColumn(pantalla.grups[0].items.filter((x, i) => i % 2 === 0));
        setRightColumn(pantalla.grups[0].items.filter((x, i) => i % 2 !== 0));
    }, []);

    useEffect(() => {
        if (
            JSON.stringify(itemSelected.sort()) ===
            JSON.stringify(correctedAnswers.sort())
        ) {
            window.localStorage.setItem(pantalla.id + "_isCorrect", 1);
        }
    }, [itemSelected]);

    const onItemClick = useCallback((index) => {
        setItemsSelected((prev) =>
            prev.includes(index)
                ? prev.filter((x) => x !== index)
                : [...prev, index]
        );
    }, []);

    const getRandomMargin = (min, max) => {
        return Math.floor(Math.random() * (max - min)) + min + "px";
    };

    const Image = memo(function Image({ src }) {
        return (
            <div
                className="fc-grup-image-for-decoration"
                style={{
                    backgroundImage: `url("/storage/${src}")`,
                    backgroundColor: pantalla.so.color,
                }}
            />
        );
    });

    return (
        <div className={"h-100 " + pantalla.tipus_machine_name}>
            {!!pantalla.grups[0] && (
                <>
                    <div className="fc-grup row justify-content-between">
                        <div className="col-md-3">
                            {!!leftColumn &&
                                leftColumn.map((item) => (
                                    <Image
                                        src={item.image_small}
                                        key={item.id}
                                    />
                                ))}
                        </div>
                        <div className="col-md-6 fc-sons-mixedup py-2">
                            {!!pantalla.grups[0].items &&
                                pantalla.grups[0].items.map((item, index) => (
                                    <div
                                        key={item.id}
                                        className="fc-so-item"
                                        style={{
                                            marginTop: getRandomMargin(0, 50),
                                            marginLeft: getRandomMargin(0, 30),
                                        }}
                                    >
                                        {!!item.audios[0] && (
                                            <AudioSelectable
                                                onAudioClick={onItemClick}
                                                index={index}
                                                audioOverColor={
                                                    pantalla.so.color
                                                }
                                                selected={itemSelected.includes(
                                                    index
                                                )}
                                                audio={item.audios[0]}
                                            />
                                        )}
                                    </div>
                                ))}
                        </div>
                        <div className="col-md-3">
                            {!!rightColumn &&
                                rightColumn.map((item) => (
                                    <Image
                                        src={item.image_small}
                                        key={item.id}
                                    />
                                ))}
                        </div>
                    </div>
                </>
            )}
        </div>
    );
});

export default ModelSonsCorrectes;
