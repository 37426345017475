import React, {useState, useEffect} from "react";
import axios from "axios";

const AppContext = React.createContext();

const AppProvider = ({children}) => {

    const [basicInformation, setBasicInformation] = useState(null);
    const [activeScreens, setActiveScreens] = useState(window.localStorage.getItem('fc_active_screens') ? JSON.stringify(window.localStorage.getItem('fc_active_screens')) : []);

    useEffect(() => {
        getBasicInformation();
        getActiveScreens();
    }, []);

    const getBasicInformation = () => {
        axios.get('/api')
            .then((resp) => {
                setBasicInformation(resp.data);
            })
    }

    const getActiveScreens = () => {
        if(localStorage.getItem('fc_active_screens')){
            setActiveScreens(JSON.parse(window.localStorage.getItem('fc_active_screens')))
        }
    }

    const setActiveScreen = (screen_id) =>{
        if(!activeScreens.includes(screen_id)){
            const array = [...activeScreens, screen_id];
            setActiveScreens(array);
            window.localStorage.setItem('fc_active_screens', JSON.stringify(array));
        }
    }

    const resetActiveScreens = () => {
        window.localStorage.removeItem('fc_active_screens');
        setActiveScreens([]);
    }

    return (
        <AppContext.Provider value={{basicInformation, setActiveScreen, activeScreens, resetActiveScreens}}>
            {children}
        </AppContext.Provider>
    )
}

export {AppContext, AppProvider};