import { useState, useContext, useEffect, memo, useCallback } from "react";
import Mot from "../items/mot";

const ModelEscoltarMarcar = ({ pantalla }) => {
    const [itemSelected, setItemsSelected] = useState([]);
    const [wrongSelected, setWrongSelection] = useState(null);
    const correctedAnswers = !!pantalla.grups[0]
        ? pantalla.grups[0].answers_array.map((el) => parseInt(el))
        : [];

    const onItemClick = useCallback((index) => {
        setWrongSelection(null);
        if (itemSelected.includes(index)) {
            setItemsSelected((prev) => prev.filter((a, i) => i !== index));
        } else {
            if (correctedAnswers.includes(index + 1)) {
                setItemsSelected((prev) => [...prev, index]);
            } else {
                setWrongSelection(index);
                setTimeout(() => {
                    setWrongSelection(null);
                }, 2000);
            }
        }
    });

    const MemoItem = memo(function MemoItem({ item, index, itemSelected }) {
        return (
            <Mot
                index={index}
                item={item}
                selectable
                isWrong={wrongSelected === index}
                motSelected={itemSelected}
                onClickMot={onItemClick}
                bgColor={pantalla.so.color}
                className={"col-md-6"}
            />
        );
    });

    return (
        <div className={"h-100 " + pantalla.tipus_machine_name}>
            {!!pantalla.grups && (
                <>
                    {pantalla.grups.map((grup) => (
                        <div
                            className="row justify-content-center"
                            key={grup.id.toString()}
                        >
                            <div
                                className={
                                    "col-lg-8 row gy-2 justify-content-start fc-grup fc-grup-" +
                                    grup.id
                                }
                            >
                                {!!grup.items && (
                                    <>
                                        {grup.items.map((item, index) => (
                                            <MemoItem
                                                key={item.id}
                                                index={index}
                                                item={item}
                                                itemSelected={itemSelected}
                                            />
                                        ))}
                                    </>
                                )}
                            </div>
                        </div>
                    ))}
                </>
            )}
        </div>
    );
};

export default ModelEscoltarMarcar;
