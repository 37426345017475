import { useContext } from "react";
import { Link, NavLink } from "react-router-dom";
import { AppContext } from "../context/AppProvider";

const TopBar = ({
    color = "primary",
    hexColor = false,
    displayLogo = false,
    displayComFunciona = false,
    nav = null,
    logo = null,
}) => {
    const { activeScreens } = useContext(AppContext);

    const styles = {};
    if (hexColor) {
        styles.backgroundColor = hexColor;
    }

    return (
        <div
            id="fc-topbar"
            className={"fc-topbar fc-color-" + color}
            style={styles}
        >
            <div className="d-flex justify-content-between align-items-center h-100 p-3">
                <div className="fc-topbar-left">
                    {displayLogo && (
                        <div>
                            {logo}
                            {!logo && (
                                <Link
                                    to="/"
                                    className="fc-topbar-logo text-dark"
                                >
                                    foneticat
                                </Link>
                            )}
                        </div>
                    )}
                </div>
                {!!nav && (
                    <div className="fc-topbar-center d-none d-lg-block">
                        <nav>
                            <ul className="fc-topbar-nav list-unstyled d-flex flex-row justify-content-center align-items-center m-0 p-0">
                                {!!nav.items &&
                                    nav.items
                                        .filter((x, i) => i !== 0)
                                        .map((item, index) => (
                                            <li
                                                className={`nav-item ${
                                                    !activeScreens.includes(
                                                        item.id
                                                    )
                                                        ? " disabled"
                                                        : ""
                                                } `}
                                                key={item.id.toString()}
                                            >
                                                {activeScreens.includes(
                                                    item.id
                                                ) && (
                                                    <NavLink
                                                        to={
                                                            "/so/" +
                                                            nav.slug +
                                                            "/" +
                                                            item.id
                                                        }
                                                    >
                                                        {item.nav_name}
                                                    </NavLink>
                                                )}
                                                {!activeScreens.includes(
                                                    item.id
                                                ) && (
                                                    <span>{item.nav_name}</span>
                                                )}
                                            </li>
                                        ))}
                            </ul>
                        </nav>
                    </div>
                )}
                <div className="fc-topbar-right">
                    <nav>
                        <ul className="fc-topbar-nav">
                            {displayComFunciona && (
                                <li>
                                    <NavLink
                                        to="/com-funciona"
                                        activeclassname="active"
                                    >
                                        Com funciona?
                                    </NavLink>
                                </li>
                            )}
                            <li>
                                <NavLink to="/sons" activeclassname="active">
                                    Sons
                                </NavLink>
                            </li>
                        </ul>
                    </nav>
                </div>
            </div>
        </div>
    );
};

export default TopBar;
