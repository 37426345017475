import React from "react";

const BottomNavigation = ({
    onNext = () => {},
    onPrev = () => {},
    nextText = false,
    canPrev = true,
    canNext = true,
    nextLabelPosition = "inline",
    nextArrowRef,
}) => {
    return (
        <div className="fc-bottom-navigation d-flex justify-content-between align-items-center position-relative">
            <div className="fc-bottom-prev mt-1 w-50 d-flex flex-row justify-content-start">
                {canPrev && (
                    <div className="d-flex flex-row align-items-center">
                        <button
                            onClick={onPrev}
                            title={"Torna enrera"}
                            disabled={!canPrev}
                        >
                            <img
                                src="/images/left-arrow.png"
                                alt={"Torna enrera"}
                            />
                        </button>
                    </div>
                )}
            </div>
            <div className="fc-bottom-next mt-1 w-50 d-flex flex-row justify-content-end">
                <div className="d-flex flex-row align-items-center">
                    <button
                        ref={nextArrowRef}
                        onClick={onNext}
                        title={nextText || "Avança"}
                        style={{ pointerEvents: "none" }}
                        className={`${
                            nextLabelPosition == "inline"
                                ? "flex-row align-items-center"
                                : "flex-column align-items-end"
                        }  d-flex text-dark opacity-50 justify-content-between`}
                    >
                        {nextText && <span className="mx-3">{nextText}</span>}
                        <img
                            src="/images/right-arrow.png"
                            alt={nextText || "Avança"}
                        />
                    </button>
                </div>
            </div>
        </div>
    );
};

export default BottomNavigation;
