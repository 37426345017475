import { Link, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import TopBar from "./partials/topBar";
import axios from "axios";
import Loader from "./partials/loader";
import ModelPortada from "./tipusPantalles/modelPortada";
import MarkdownIt from "markdown-it";
import { Helmet } from "react-helmet";

const So = () => {
    const { slug } = useParams();
    const [so, setSo] = useState(null);
    const [loading, setLoading] = useState(true);
    const [displayDescription, setDisplayDescription] = useState(false);
    const markdownParser = new MarkdownIt({
        html: true,
    });

    useEffect(() => {
        getPrimeraPantalla();
    }, [slug]);

    useEffect(() => {
        return () => {
            setSo(null);
        };
    }, []);

    const getPrimeraPantalla = () => {
        setLoading(true);
        axios
            .get("/api/so/" + slug)
            .then((resp) => {
                setSo(resp.data);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const Logo = ({ symbol, setDisplayDescription, displayDescription }) => {
        return (
            <span className="fc-so-logo d-flex">
                <img src={symbol} height="60" alt={slug} />
                <a
                    className="d-block icon-i"
                    onClick={() => setDisplayDescription(!displayDescription)}
                >
                    <img src="/images/info.svg" />
                </a>
            </span>
        );
    };

    return (
        <div
            className="container-fluid min-vh-100 h-100 d-flex flex-column justify-content-between position-relative so-screen-bg"
            style={{ backgroundColor: (so && so.color) || "transparent" }}
        >
            <Helmet>
                <title>
                    Foneticat |{" "}
                    {!!so ? so?.name.replace(/(<([^>]+)>)/gi, "") : "Sons"}
                </title>
            </Helmet>
            <TopBar
                displayLogo
                displayComFunciona={false}
                hexColor={so && so.color}
                logo={
                    so && (
                        <Logo
                            symbol={so.symbol}
                            setDisplayDescription={setDisplayDescription}
                            displayDescription={displayDescription}
                        />
                    )
                }
            />
            {so && (
                <>
                    <div className="container-xxl d-flex flex-column h-100 justify-content-between">
                        <div className="row align-items-center justify-content-center my-4">
                            <div
                                className={`col col-md-6 col-xl-7 d-flex align-items-center justify-content-center fc-so-heading`}
                            >
                                <h1
                                    className={`${
                                        displayDescription
                                            ? "visually-hidden"
                                            : ""
                                    } text-center d-block fc-portada-heading`}
                                    dangerouslySetInnerHTML={{
                                        __html: so.example_phrase,
                                    }}
                                />
                                <div
                                    className={`${
                                        !displayDescription
                                            ? "visually-hidden"
                                            : ""
                                    } fc-so-description text-center position-relative`}
                                >
                                    <div className="fc-so-descriptio-closer">
                                        <a
                                            onClick={() =>
                                                setDisplayDescription(false)
                                            }
                                        >
                                            <img src="/images/icn-flecha-cerrar.svg" />
                                        </a>
                                    </div>
                                    <span
                                        dangerouslySetInnerHTML={{
                                            __html: markdownParser.render(
                                                so.description
                                            ),
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                        {!!so.primera_pantalla && (
                            <ModelPortada
                                pantalla={so.primera_pantalla[0]}
                                layout="portada"
                            />
                        )}
                    </div>
                    <div className="fc-bottom-nav-static d-flex flex-row justify-content-end align-items-center p-md-3 p-2">
                        <Link
                            to={"/so/" + slug + "/" + so.navigation[0 + 1].id}
                        >
                            <img src="/images/right-arrow.svg" alt="Comença" />
                        </Link>
                    </div>
                </>
            )}
            {!displayDescription && <div id="graphic-so" />}
            <Loader visible={loading} />
        </div>
    );
};

export default So;
