import React, {
    useState,
    useEffect,
    useContext,
    memo,
    useCallback,
    useRef,
    useLayoutEffect,
} from "react";
import { useParams, useNavigate } from "react-router";
import Loader from "./partials/loader";
import axios from "axios";
import TopBar from "./partials/topBar";
import ModelPortada from "./tipusPantalles/modelPortada";
import BottomNavigation from "./partials/bottomNavigation";
import MarkdownIt from "markdown-it";
import ModelReconeixer from "./tipusPantalles/modelReconeixer";
import ModelComparar from "./tipusPantalles/modelComparar";
import ModelPortadaVideosDesiguals from "./tipusPantalles/modelPortadaVideosDesiguals";
import ModelParellesIdentic from "./tipusPantalles/modelParellesIdentic";
import ModelAgrupar from "./tipusPantalles/modelAgrupar";
import ModelParelles from "./tipusPantalles/modelParelles";
import ModelSoCorrecte from "./tipusPantalles/modelSoCorrecte";
import ModelSonsCorrectes from "./tipusPantalles/modelSonsCorrectes";
import { AppContext } from "./context/AppProvider";
import { Link, NavLink } from "react-router-dom";
import ModelEscoltarMarcar from "./tipusPantalles/modelEscoltarMarcar";

const SCROLL_TO_CONTINUE = 100;

const Pantalla = () => {
    const { slug, pid } = useParams();
    const navigate = useNavigate();
    const { setActiveScreen } = useContext(AppContext);
    const [loading, setLoading] = useState(true);
    const [pantalla, setPantalla] = useState(null);
    const [feedBackScreen, setFeedBackScreen] = useState(false);
    const [feedBackText, setFeedbackText] = useState(null);
    const [feedbackStatus, setFeedbackStatus] = useState(null);
    const [finalScreen, setFinalScreen] = useState(false);
    const markdownParser = new MarkdownIt({
        html: true,
    });
    const scrollWindow = useRef();
    const nextArrow = useRef();

    useEffect(() => {
        getPantalla();
        setFeedBackScreen(false);
        setFinalScreen(false);

        return () => {};
    }, [pid]);

    useLayoutEffect(() => {
        if (!!scrollWindow.current && !!nextArrow.current) {
            scrollWindow.current.scrollTo({ top: 0 });
            if (
                scrollWindow.current.scrollHeight <=
                scrollWindow.current.clientHeight
            ) {
                enableNextArrow(nextArrow);
            } else {
                disableNextArrow(nextArrow);
            }

            if (window.innerWidth <= 768) {
                enableNextArrow(nextArrow);
            }

            return () => {
                disableNextArrow(nextArrow);
            };
        }
    }, [pantalla, scrollWindow, nextArrow.current]);

    const getPantalla = () => {
        setLoading(true);
        axios
            .get("/api/so/" + slug + "/" + pid)
            .then((resp) => {
                if (resp.data.so.navigation[0].id === resp.data.id) {
                    window.location.href = "/so/" + resp.data.so.slug;
                }
                setPantalla(resp.data);
                setActiveScreen(resp.data.id);
            })
            .finally(() => {
                setLoading(false);
                window.scrollTo({ top: 0, behavior: "smooth" });
            });
    };

    const findCurrentPantallaIndex = (navigation) => {
        return navigation.findIndex((el) => parseInt(el.id) === parseInt(pid));
    };

    const TipusPantallaSwitcher = memo(function TipusPantallaSwitcher() {
        switch (pantalla.tipus_machine_name) {
            case "model_portada":
                return <ModelPortada pantalla={pantalla} onScroll={onScroll} />;
            case "model_reconeixer":
                return (
                    <ModelReconeixer pantalla={pantalla} onScroll={onScroll} />
                );
            case "model_escoltar_marcar":
                return (
                    <ModelEscoltarMarcar
                        pantalla={pantalla}
                        onScroll={onScroll}
                    />
                );
            case "model_comparar":
            case "model_comparar_notext":
            case "model_comparar_expressions":
            case "model_comparar_cancons":
                return (
                    <ModelComparar pantalla={pantalla} onScroll={onScroll} />
                );
            case "model_portada_videos_desiguals":
                return (
                    <ModelPortadaVideosDesiguals
                        pantalla={pantalla}
                        onScroll={onScroll}
                    />
                );
            case "model_parelles_identic":
                return (
                    <ModelParellesIdentic
                        pantalla={pantalla}
                        onScroll={onScroll}
                    />
                );
            case "model_agrupar":
                return <ModelAgrupar pantalla={pantalla} onScroll={onScroll} />;
            case "model_parelles":
                return (
                    <ModelParelles pantalla={pantalla} onScroll={onScroll} />
                );
            case "model_so_correcte":
                return (
                    <ModelSoCorrecte pantalla={pantalla} onScroll={onScroll} />
                );
            case "model_sons_correctes":
                return (
                    <ModelSonsCorrectes
                        pantalla={pantalla}
                        onScroll={onScroll}
                    />
                );
            default:
                return <></>;
        }
    });

    const Logo = ({ slug, symbol }) => {
        return (
            <NavLink to={"/so/" + slug} className="fc-so-logo d-flex">
                <img height="60" src={symbol} alt={slug} />
            </NavLink>
        );
    };

    const displayFeedback = () => {
        if (!pantalla.message_success) {
            return false;
        }
        if (window.localStorage.getItem(pantalla.id + "_isCorrect")) {
            setFeedBackScreen(true);
            if (window.localStorage.getItem(pantalla.id + "_isCorrect") == 1) {
                setFeedbackStatus(true);
                setFeedbackText(
                    pantalla.message_success !== null
                        ? pantalla.message_success
                        : ""
                );
            } else {
                setFeedbackStatus(false);
                setFeedbackText(
                    pantalla.message_error !== null
                        ? pantalla.message_error
                        : ""
                );
            }
            window.scrollTo({ top: 0, behavior: "smooth" });
            return true;
        } else if (pantalla.message_success !== null) {
            setFeedBackScreen(true);
            setFeedbackStatus(true);
            setFeedbackText(pantalla.message_success);
            window.scrollTo({ top: 0, behavior: "smooth" });
            return true;
        }
        return false;
    };

    const handlePrev = () => {
        if (finalScreen) {
            setFinalScreen(false);
            return;
        }
        if (feedBackScreen) {
            setFeedBackScreen(false);
            return;
        }
        const index = findCurrentPantallaIndex(pantalla.so.navigation);
        if (index === 1) {
            navigate("/so/" + pantalla.so.slug);
            return;
        }
        if (index > 0) {
            navigate(
                "/so/" +
                    pantalla.so.slug +
                    "/" +
                    pantalla.so.navigation[index - 1].id
            );
        }
    };

    const handleNext = useCallback(() => {
        if (!feedBackScreen && displayFeedback()) {
            return;
        }
        const index = findCurrentPantallaIndex(pantalla.so.navigation);
        if (!!pantalla.so.navigation[index + 1]) {
            navigate(
                "/so/" +
                    pantalla.so.slug +
                    "/" +
                    pantalla.so.navigation[index + 1].id
            );
        }
        if (finalScreen) {
            navigate("/sons");
        }
        if (pantalla.so.navigation.length === index + 1) {
            setFinalScreen(true);
        }
    });

    const onScroll = useCallback((ev) => {
        if (
            !!nextArrow.current &&
            (ev.target.scrollHeight === ev.target.clientHeight ||
                ev.target.scrollTop > SCROLL_TO_CONTINUE)
        ) {
            enableNextArrow(nextArrow);
        }
    });

    const enableNextArrow = useCallback((nextArrow) => {
        nextArrow.current.disabled = false;
        nextArrow.current.classList.remove("opacity-50");
        nextArrow.current.style.pointerEvents = "auto";
    });

    const disableNextArrow = useCallback((nextArrow) => {
        nextArrow.current.disabled = true;
        nextArrow.current.classList.add("opacity-50");
        nextArrow.current.style.pointerEvents = "none";
    });

    return (
        <div
            className={`min-vh-100 h-100 d-flex flex-column justify-content-between ${
                feedBackScreen && "feedback-screen"
            } ${feedbackStatus ? "ok" : "ko"}  ${
                finalScreen && "final-screen-bg"
            }`}
            style={{
                backgroundColor:
                    pantalla &&
                    pantalla.so.color &&
                    (feedBackScreen || finalScreen)
                        ? pantalla.so.color
                        : "transparent",
            }}
        >
            {pantalla && (
                <>
                    <TopBar
                        displayLogo
                        logo={
                            <Logo
                                slug={pantalla.so.slug}
                                symbol={pantalla.so.symbol}
                            />
                        }
                        displayComFunciona={false}
                        hexColor={pantalla.so.color}
                        nav={{
                            items: pantalla.so.navigation,
                            current: pid,
                            slug: pantalla.so.slug,
                        }}
                    />
                    <div className="container-xxl d-flex flex-column h-100 justify-content-start flex-fill">
                        {!!pantalla.description &&
                            !feedBackScreen &&
                            !finalScreen && (
                                <div className="row justify-content-start py-3">
                                    <div className="col-md-12">
                                        <span
                                            className="font-normal"
                                            dangerouslySetInnerHTML={{
                                                __html: markdownParser.render(
                                                    pantalla.description
                                                ),
                                            }}
                                        />
                                    </div>
                                </div>
                            )}
                        {!feedBackScreen && !finalScreen && (
                            <div
                                className="fc-pantalla"
                                ref={scrollWindow}
                                onScroll={onScroll}
                            >
                                <TipusPantallaSwitcher pantalla={pantalla} />
                            </div>
                        )}
                        {feedBackScreen && feedBackText && (
                            <div className="h-100 d-flex flex-column feedback-screen justify-content-center flex-fill align-items-center text-center">
                                <div className="row col-md-6">
                                    <div
                                        className="text-center mb-2"
                                        dangerouslySetInnerHTML={{
                                            __html: markdownParser.render(
                                                feedBackText
                                            ),
                                        }}
                                    />
                                </div>
                            </div>
                        )}
                        {finalScreen && (
                            <div className="h-100 d-flex final-screen justify-content-center flex-fill align-items-center">
                                <div className="text-center row col-md-6">
                                    <div
                                        className="p-margin"
                                        dangerouslySetInnerHTML={{
                                            __html: markdownParser.render(
                                                pantalla.so.message_final
                                            ),
                                        }}
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                    <BottomNavigation
                        onPrev={handlePrev}
                        onNext={handleNext}
                        nextLabelPosition={finalScreen ? "block" : "inline"}
                        nextText={finalScreen ? "Triau un altre so" : false}
                        nextArrowRef={nextArrow}
                    />
                </>
            )}
            <Loader visible={loading} />
        </div>
    );
};

export default Pantalla;
