import React, { useContext, useEffect } from "react";
import TopBar from "./partials/topBar";
import { AppContext } from "./context/AppProvider";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

const Sons = () => {
    const { basicInformation, resetActiveScreens } = useContext(AppContext);

    useEffect(() => {
        resetActiveScreens();
    }, []);

    const SoItem = ({ so }) => {
        return (
            <div className="fc-so-item col-6 col-md-4 no-gutters text-center">
                <Helmet>
                    <title>Foneticat | Sons</title>
                </Helmet>
                <Link
                    to={"/so/" + so.slug}
                    className="body-color d-block h-100 position-relative py-4"
                >
                    <div className="fc-so-inner d-flex justify-content-center align-items-center h-100 flex-column">
                        <div
                            className="fc-so-name"
                            dangerouslySetInnerHTML={{ __html: so.name }}
                        />
                        <div className="fc-so-symbol">
                            <img src={so.symbol} alt={so.name} />
                        </div>
                    </div>
                    <div
                        className="fc-so-hover"
                        style={{
                            backgroundColor: (so && so.color) || "transparent",
                        }}
                    >
                        <span
                            dangerouslySetInnerHTML={{
                                __html: so.example_phrase,
                            }}
                        />
                    </div>
                </Link>
            </div>
        );
    };

    return (
        <div className="vh-100 d-flex flex-column">
            <TopBar displayLogo={true} displayComFunciona={true} />
            {basicInformation && (
                <div className="h-100 d-flex flex-row flex-wrap flex-grow-1">
                    {basicInformation.sons &&
                        basicInformation.sons.map((so) => (
                            <SoItem key={so.id.toString()} so={so} />
                        ))}
                </div>
            )}
        </div>
    );
};

export default Sons;
