const Tooltip = ({visible, text}) => {
    if (!visible) {
        return null;
    }
    return (
        <div className="fc-tooltip">
            <span dangerouslySetInnerHTML={{__html: text}} />
            <span className="fc-tooltip-arrow" />
        </div>
    )
}

export default Tooltip;