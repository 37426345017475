import { useState, useContext, useEffect, memo } from "react";
import Mot from "../items/mot";

const ModelComparar = ({ pantalla }) => {
    useEffect(() => {
        //setCanNext(false);
    }, []);

    const setTextType = () => {
        switch (pantalla.tipus_machine_name) {
            case "model_comparar_notext":
                return "text-out";
            case "model_comparar_expressions":
                return "text-middle";
            case "model_comparar_cancons":
                return "text-middle-scroll";
            default:
                return "text-in";
        }
    };

    return (
        <div className={"h-100 " + pantalla.tipus_machine_name}>
            {!!pantalla.grups && (
                <>
                    {pantalla.grups.map((grup, gindex) => (
                        <div key={gindex}>
                            {grup.name && (
                                <div className="text-center">
                                    <h4>{grup.name}</h4>
                                </div>
                            )}
                            <div
                                className="row justify-content-center mb-4"
                                key={grup.id.toString()}
                            >
                                <div
                                    className={
                                        "col-lg-8 row gy-2 justify-content-start fc-grup fc-grup-" +
                                        grup.id
                                    }
                                >
                                    {!!grup.items && (
                                        <>
                                            {grup.items.map((item, index) => (
                                                <Mot
                                                    key={item.id}
                                                    index={index}
                                                    item={item}
                                                    selectable={false}
                                                    className={`col-md-6 mb-2 ${
                                                        pantalla.tipus_machine_name ===
                                                        "model_comparar_notext"
                                                            ? "mb-0"
                                                            : "mb-4"
                                                    }`}
                                                    bgColor={pantalla.so.color}
                                                    typeBgColor="shadows"
                                                    textType={setTextType()}
                                                />
                                            ))}
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                    ))}
                </>
            )}
        </div>
    );
};

export default ModelComparar;
