import Mot from "../items/mot";
import { useCallback, useState } from "react";

const ModelParellesIdentic = ({ pantalla }) => {
    const [itemSelected, setItemsSelected] = useState([]);
    const [wrongSelected, setWrongSelection] = useState(null);
    const answers = pantalla.answers_array.map((a) => parseInt(a));

    const onItemClick = (index) => {
        setWrongSelection(null);
        if (itemSelected.includes(index)) {
            setItemsSelected((prev) => prev.filter((a, i) => a !== index));
        } else {
            if (answers.includes(index + 1)) {
                setItemsSelected((prev) => [...prev, index]);
            } else {
                setWrongSelection(index);
                setTimeout(() => {
                    setWrongSelection(null);
                }, 2000);
            }
        }
    };

    return (
        <div className={"h-100 " + pantalla.tipus_machine_name}>
            {!!pantalla.grups && (
                <>
                    <div className="row justify-content-center">
                        <div className="col-lg-10">
                            <div className="row justify-content-evenly position-relative mb-3">
                                {pantalla.grups.length > 1 &&
                                    pantalla.grups.map((grup, gIndex) => (
                                        <div
                                            className={`col-12 col-md-4 col-lg-4 mb-5 fc-grup fc-grup-selectable ${
                                                wrongSelected === gIndex
                                                    ? "is-wrong"
                                                    : ""
                                            } ${
                                                itemSelected.includes(gIndex)
                                                    ? "selected"
                                                    : ""
                                            }`}
                                            key={grup.id}
                                        >
                                            <div
                                                className="fc-grup-bg"
                                                style={{
                                                    borderColor:
                                                        pantalla.so.color,
                                                }}
                                            />
                                            {grup.items.length >= 2 &&
                                                grup.items.map(
                                                    (item, index) => (
                                                        <Mot
                                                            key={item.id}
                                                            onClickMot={
                                                                onItemClick
                                                            }
                                                            item={item}
                                                            index={index}
                                                            grupIndex={gIndex}
                                                            selectable={false}
                                                            audioPlacement="right"
                                                            grupSelectable={
                                                                true
                                                            }
                                                            bgColor={
                                                                pantalla.so
                                                                    .color
                                                            }
                                                            size="small"
                                                            className="py-2"
                                                        />
                                                    )
                                                )}
                                            <div
                                                className="fc-grup-selectable-over"
                                                onClick={() =>
                                                    onItemClick(gIndex)
                                                }
                                            >
                                                {itemSelected.includes(
                                                    gIndex
                                                ) && (
                                                    <img src="/images/check.svg" />
                                                )}
                                                {wrongSelected === gIndex && (
                                                    <img
                                                        height="100"
                                                        src="/images/icn-flecha-cerrar.svg"
                                                    />
                                                )}
                                            </div>
                                        </div>
                                    ))}
                            </div>
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};

export default ModelParellesIdentic;
