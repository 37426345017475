import VideoItem from "../items/video";
import Mot from "../items/mot";
import { Fragment } from "react";

const ModelPortadaVideosDesiguals = ({ pantalla, layout }) => {
    return (
        <div
            className={
                "h-100 row justify-content-center " +
                pantalla.tipus_machine_name
            }
        >
            {!!pantalla.grups && (
                <>
                    <div className="col-md-8">
                        <div className="row justify-content-center f-grup-videos pb-4">
                            {!!pantalla.grups[0].items && (
                                <>
                                    {pantalla.grups[0].items.map((item) => (
                                        <VideoItem
                                            key={item.id.toString()}
                                            item={item}
                                            className={"col-12 col-md-6 gy-2"}
                                        />
                                    ))}
                                </>
                            )}
                        </div>
                    </div>
                    <div className="row justify-content-evenly gy-2">
                        {pantalla.grups.length > 1 &&
                            pantalla.grups
                                .filter((g, i) => i !== 0)
                                .map((grup) => (
                                    <div
                                        className="col-lg-5 d-flex flex-column flex-md-row justify-content-center align-items-center align-items-md-start mb-sm-2"
                                        key={grup.id}
                                    >
                                        {grup.items.length >= 2 &&
                                            grup.items.map((item, index) => (
                                                <Fragment key={item.id}>
                                                    <Mot
                                                        item={item}
                                                        index={index}
                                                        selectable={false}
                                                        audioPlacement="default"
                                                        bgColor={
                                                            pantalla.so.color
                                                        }
                                                        size="small"
                                                        className="py-2"
                                                    />
                                                    {index === 0 && (
                                                        <div className="px-2 text-center fc-icon-desigual">
                                                            <img src="/images/desigual.svg" />
                                                        </div>
                                                    )}
                                                </Fragment>
                                            ))}
                                    </div>
                                ))}
                    </div>
                </>
            )}
        </div>
    );
};

export default ModelPortadaVideosDesiguals;
