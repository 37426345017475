import { Helmet } from "react-helmet";
import TopBar from "./partials/topBar";

const CookiesPage = () => {
    return (
        <div className="min-vh-100 h-100 d-flex flex-column  position-relative">
            <Helmet>
                <title>Foneticat | Què són les galetes?</title>
            </Helmet>
            <TopBar displayLogo={true} displayComFunciona={true} />
            <div className="container-xxl d-flex flex-column align-items-center h-100">
                <div className="row justify-content-center align-items-center">
                    <div className="col-md-8 p-4">
                        <h1 className="h4">QUÈ SÓN LES GALETES?</h1>

                        <p>
                            Aquest lloc web utilitza galetes i/o tecnologies
                            similars que emmagatzemen i recuperen informació
                            quan navegau. En general, aquestes tecnologies poden
                            servir per a finalitats molt diverses, com per
                            exemple conèixer-vos com a usuari, obtenir
                            informació sobre els vostres hàbits de navegació o
                            personalitzar la forma en què es mostra el
                            contingut.
                        </p>

                        <p>
                            Es poden distingir dos tipus de galetes: les galetes
                            pròpies, és a dir, que genera i gestiona el titular
                            del web, i les galetes de tercers, o sigui, les que
                            generen serveis o proveïdors externs i proporcionen
                            una eina o una funcionalitat integrada al web.
                        </p>

                        <p>
                            Els usos concrets que feim d'aquestes tecnologies es
                            descriuen a continuació.
                        </p>

                        <p className="pt-2">
                            <strong>
                                QUIN TIPUS DE GALETES UTILITZAM I PER A QUÈ?
                            </strong>
                        </p>

                        <p>
                            Utilitzam galetes tècniques, indispensables per a la
                            navegació i la utilització de les diferents opcions
                            i serveis del lloc web, com ara:
                        </p>

                        <ul>
                            <li>
                                Controlar el trànsit i la comunicació de dades.
                            </li>
                            <li>Identificar la sessió.</li>
                            <li>Accedir a parts d'accés restringit.</li>
                            <li>
                                Recordar els elements que integren una comanda.
                            </li>
                            <li>
                                Fer el procés de compra d'una comanda
                                <strong> </strong>i gestionar-ne el pagament.
                            </li>
                            <li>
                                Controlar el frau vinculat a la seguretat del
                                servei.
                            </li>
                            <li>
                                Fer la sol·licitud d'inscripció o participació
                                en un esdeveniment.
                            </li>
                            <li>
                                Comptar visites a l'efecte de la facturació de
                                llicències del programari amb el qual funciona
                                el servei (lloc web, plataforma o aplicació).
                            </li>
                            <li>
                                Emprar elements de seguretat durant la
                                navegació.
                            </li>
                            <li>
                                Emmagatzemar continguts per a la difusió de
                                vídeos o de so.
                            </li>
                            <li>
                                Habilitar continguts dinàmics (per exemple,
                                animació de càrrega d'un text o imatge).
                            </li>
                            <li>
                                Compartir continguts a través de xarxes socials.
                            </li>
                        </ul>

                        <p>
                            També s'inclouen en aquesta categoria les galetes
                            destinades a la gestió dels espais publicitaris del
                            web sobre la base de criteris tècnics, com és ara el
                            contingut editat, la freqüència en què es mostren
                            els anuncis o el control de l'origen del client
                            potencial quan aquest hagi activat un enllaç
                            publicitari des d'un web adherit a programes de
                            màrqueting per afiliació.
                        </p>

                        <p>
                            Aquestes galetes queden exemptes del compliment de
                            les obligacions establertes a l'article 22.2 de la
                            LSSI.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CookiesPage;
