import { useContext } from "react";
import TopBar from "./partials/topBar";
import { Link } from "react-router-dom";
import MarkdownIt from "markdown-it";
import { AppContext } from "./context/AppProvider";
import { Helmet } from "react-helmet";

const ComFunciona = () => {
    const { basicInformation } = useContext(AppContext);

    const markdownParser = new MarkdownIt({
        html: true,
    });

    return (
        <div className="min-vh-100 h-100 d-flex flex-column justify-content-between position-relative">
            <Helmet>
                <title>Foneticat | Com funciona?</title>
            </Helmet>
            <TopBar displayLogo={true} displayComFunciona={true} />
            <div className="fc-pantalla container-xxl d-flex flex-column align-items-center h-100">
                <div className="row justify-content-center align-items-center">
                    <div className="col-md-8 p-4">
                        {basicInformation && basicInformation.comFunciona && (
                            <span
                                className="capitalize-first-letter p-margin"
                                dangerouslySetInnerHTML={{
                                    __html: markdownParser.render(
                                        basicInformation.comFunciona
                                    ),
                                }}
                            />
                        )}
                        <div
                            id="com-funciona-graphics"
                            className="d-flex justify-content-end d-none d-lg-flex"
                        >
                            <img
                                src="/images/microfon.png"
                                height={100}
                                alt=""
                            />
                            <img
                                src="/images/auriculares.png"
                                height={100}
                                alt=""
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="fc-bottom-nav-static d-flex flex-row justify-content-end align-items-center">
                <Link to="/sons" className="body-color">
                    <div className="d-flex flex-row align-items-center">
                        <span className="mx-3 fc-arrow-title d-none d-md-inline">
                            Comencem!
                        </span>
                        <img src="/images/right-arrow.svg" alt="Comença" />
                    </div>
                </Link>
            </div>
        </div>
    );
};

export default ComFunciona;
