import VideoItem from "../items/video";

const ModelPortada = ({ pantalla, layout }) => {
    return (
        <div
            className={
                "h-100 p-3 flex-fill d-flex align-items-center " +
                pantalla.tipus_machine_name
            }
        >
            {!!pantalla.grups && (
                <>
                    {pantalla.grups.map((grup) => (
                        <div
                            className="row w-100 justify-content-center"
                            key={grup.id.toString()}
                        >
                            <div
                                className={
                                    `${
                                        layout === "portada"
                                            ? "col"
                                            : "col-xl-8"
                                    } row gy-2 justify-content-center fc-grup fc-grup-` +
                                    grup.id
                                }
                            >
                                {!!grup.items && (
                                    <>
                                        {grup.items.map((item) => (
                                            <VideoItem
                                                key={item.id.toString()}
                                                item={item}
                                                className={
                                                    layout === "portada"
                                                        ? "col-md-6 col-xl-3"
                                                        : "col-xl-6"
                                                }
                                            />
                                        ))}
                                    </>
                                )}
                            </div>
                        </div>
                    ))}
                </>
            )}
        </div>
    );
};

export default ModelPortada;
