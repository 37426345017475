import { Link, NavLink } from "react-router-dom";
import TopBar from "./partials/topBar";
import { useContext } from "react";
import { AppContext } from "./context/AppProvider";
import { Helmet } from "react-helmet";

const Home = () => {
    const { basicInformation } = useContext(AppContext);
    return (
        <div className="min-vh-100 h-100 d-flex flex-column justify-content-between position-relative p-home">
            <Helmet>
                <title>
                    Foneticat | Perfeccionament de la pronúncia en català
                </title>
                <meta
                    name="description"
                    content="Hem triat nou sons de la llengua catalana i us els oferim amb exemples i exercicis per ajudar-vos a millorar-ne la pronúncia."
                />
            </Helmet>
            <TopBar displayComFunciona={true} />
            <div className="container-xxl d-flex flex-column align-items-center justify-content-center h-100">
                <div className="fc-home-logo pb-2">
                    <Link class="text-dark" to="/sons">
                        {basicInformation
                            ? basicInformation.siteName
                            : "foneticat"}
                    </Link>
                </div>
                <div className="fc-home-subtitle animate__animated animate__slideInUp">
                    <img src="/images/logo.svg" alt="Foneticat" />
                </div>
            </div>
            <div className="fc-bottom-nav-static d-flex flex-row justify-content-end align-items-center">
                <Link to="/com-funciona">
                    <img src="/images/right-arrow.svg" alt="Comença" />
                </Link>
            </div>
            <div className="d-flex flex-row justify-content-center align-items-center position-absolute w-100 bottom-0 text-center">
                <a
                    href="https://llenguamallorca.cat"
                    className="text-dark p-2 small"
                    target="_blank"
                >
                    Llengua Mallorca
                </a>
                <NavLink
                    to="/politica-galletes"
                    className="text-dark p-2 small"
                    target="_blank"
                >
                    Política de galetes
                </NavLink>
            </div>
            <div id="graphic-home" />
        </div>
    );
};

export default Home;
