import {Link} from "react-router-dom";
import TopBar from "./partials/topBar";

const NotFoundPage = () => {
    return (
        <div className="min-vh-100 h-100 d-flex flex-column justify-content-start position-relative">
            <TopBar displayComFunciona={true} />
            <div className="container flex-fill d-flex flex-column align-items-center justify-content-center h-100">
                <div className="row col-md-8 h-100">
                    <h1>Aquesta pàgina no existeix</h1>
                    <p>No hem trobat a pàgina que cercaves. Podeu tornar a l'<a href="/sons" className="text-dark"><u> inici</u></a> i provar de nou</p>
                </div>
            </div>
        </div>
    )
}

export default NotFoundPage;