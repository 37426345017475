import { useState, useRef, useEffect } from "react";
import Modal from "../partials/modal";
import MarkdownIt from "markdown-it";

const isIpadOS = () => {
    return (
        navigator.maxTouchPoints &&
        navigator.maxTouchPoints > 2 &&
        navigator.platform === "MacIntel"
    );
};

const VideoItem = ({ item, className }) => {
    const markdownParser = new MarkdownIt({
        html: true,
    });
    const videoRef = useRef(null);
    const videoThumbRef = useRef(null);
    const [isPlaying, setIsPlaying] = useState(false);
    const [displayModal, setDisplayModal] = useState(false);

    useEffect(() => {
        let keyListener = document.addEventListener("keydown", (ev) => {
            if (ev.key === "Escape") {
                setDisplayModal(false);
            }
        });

        return () => {
            document.removeEventListener("keydown", null);
        };
    }, []);

    const handleVideoClick = () => {
        if (isPlaying) {
            videoRef.current.pause();
        } else {
            videoRef.current.play();
        }
    };

    const onModalClose = () => {
        setDisplayModal(false);
    };

    const handleMouseEnter = () => {
        let randomRotate = Math.floor(Math.random() * 3) + 1;
        randomRotate *= Math.round(Math.random()) ? 1 : -1;
        videoThumbRef.current.style.transform =
            "rotate(" + randomRotate + "deg)";
    };

    const handleMouseLeave = () => {
        videoThumbRef.current.style.transform = "rotate(0deg)";
    };

    return (
        <div className={className}>
            <div
                className="fc-video-thumb"
                ref={videoThumbRef}
                onClick={() => setDisplayModal(true)}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
            >
                <div
                    className="fc-video-content"
                    dangerouslySetInnerHTML={{
                        __html: markdownParser.render(item.text),
                    }}
                />

                <video
                    className="fc-video h-100"
                    autoPlay={false}
                    controls={false}
                    playsInline
                    width="100%"
                    height="auto"
                    preload={isIpadOS() ? "auto" : "metadata"}
                >
                    <source
                        src={"/storage/" + item.video_url}
                        type="video/mp4"
                    />
                </video>
            </div>
            <Modal visible={displayModal} onClose={onModalClose}>
                <div className="fc-video-modal-content">
                    <div
                        className="fc-video-modal-text"
                        dangerouslySetInnerHTML={{
                            __html: markdownParser.render(item.text),
                        }}
                    />

                    <video
                        ref={videoRef}
                        className="fc-video h-100"
                        autoPlay={true}
                        onPause={() => setIsPlaying(false)}
                        onPlaying={() => setIsPlaying(true)}
                        controls={false}
                        onClick={() => handleVideoClick()}
                    >
                        <source src={"/storage/" + item.video_url} />
                    </video>
                </div>
            </Modal>
        </div>
    );
};

export default VideoItem;
