import React from "react";
import { BrowserRouter, Link, Route, Routes } from "react-router-dom";
import Home from "./home";
import ComFunciona from "./comFunciona";
import Sons from "./sons";
import { AppProvider } from "./context/AppProvider";
import So from "./so";
import Pantalla from "./pantalla";
import NotFoundPage from "./NotFoundPage";
import CookiesPage from "./cookiesPage";

const App = () => {
    return (
        <AppProvider>
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/com-funciona" element={<ComFunciona />} />
                    <Route path="/sons" element={<Sons />} />
                    <Route path="/so/:slug" element={<So />} />
                    <Route path="/so/:slug/:pid" element={<Pantalla />} />
                    <Route
                        path="/politica-galletes"
                        element={<CookiesPage />}
                    />
                    <Route path="*" element={<NotFoundPage />} />
                </Routes>
            </BrowserRouter>
        </AppProvider>
    );
};

export default App;
