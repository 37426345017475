import { useState, useEffect } from "react";
import Tooltip from "./tooltip";

const MicroComponent = ({
    url,
    audioRecordingCallback = () => {},
    overColor = "#ffffff",
}) => {
    const [jsAudio, setJsAudio] = useState(new Audio("/storage/" + url));
    const [recorderStream, setRecorderStream] = useState(null);
    const [recordedAudioUrl, setRecordedAudioUrl] = useState(null);
    const [isRecording, setIsRecording] = useState(false);
    const [canRecord, setCanRecord] = useState(true);
    const [showRecordTooltip, setShowRecordTooltip] = useState(false);
    const [showCompareTooltip, setShowCompareTooltip] = useState(false);
    const [svgBackground, setSvgBackground] = useState("#ffffff");
    const [svgCompareBackground, setSvgCompareBackground] = useState("#ffffff");
    const [isPlaying, setIsPlaying] = useState(false);

    useEffect(() => {
        if (jsAudio) {
            jsAudio.onended = () => {
                setIsPlaying(false);
                setSvgBackground("#ffffff");
            };
        }

        return () => {
            jsAudio.pause();
            jsAudio.currentTime = 0;
            setRecorderStream(null);
            setRecordedAudioUrl(null);
            setIsRecording(false);
        };
    }, []);

    const handleClick = async () => {
        if (!canRecord) {
            return;
        }

        if (jsAudio && !jsAudio.paused) {
            jsAudio.pause();
            jsAudio.currentTime = 0;
        }

        setRecordedAudioUrl(null);

        if (recorderStream) {
            if (isRecording) {
                recorderStream.stop();
                setIsRecording(false);
                audioRecordingCallback(false);
            } else {
                recorderStream.start();
                setIsRecording(true);
                audioRecordingCallback(true);
            }
            recorderStream.addEventListener("dataavailable", (e) => {
                const recorded = URL.createObjectURL(e.data);
                setRecordedAudioUrl(new Audio(recorded));
            });
            return;
        }

        navigator.mediaDevices
            .getUserMedia({ audio: true })
            .then((stream) => {
                if (stream.active) {
                    const mediaRecorder = new MediaRecorder(stream);
                    setRecorderStream(mediaRecorder);
                    mediaRecorder.start();
                    setIsRecording(true);
                    audioRecordingCallback(true);

                    setTimeout(() => {
                        if (mediaRecorder.state === "recording") {
                            mediaRecorder.stop();
                            setIsRecording(false);
                            audioRecordingCallback(false);

                            mediaRecorder.addEventListener(
                                "dataavailable",
                                (e) => {
                                    const recorded = URL.createObjectURL(
                                        e.data
                                    );
                                    setRecordedAudioUrl(new Audio(recorded));
                                }
                            );
                        }
                    }, 10000);
                }
            })
            .catch(() => {
                alert("No podem accedir al vostre micròfon");
            });
    };

    const handleCompareClick = () => {
        stopAll();

        if (recordedAudioUrl) {
            //setCanRecord(false);
            recordedAudioUrl.play();
            setIsPlaying(true);
            recordedAudioUrl.onended = () => {
                jsAudio.play();
            };
        }
    };

    const stopAll = () => {
        if (!jsAudio.paused) {
            jsAudio.pause();
            jsAudio.currentTime = 0;
        }
        if (!recordedAudioUrl.paused) {
            recordedAudioUrl.pause();
            recordedAudioUrl.currentTime = 0;
        }
    };

    return (
        <>
            {recordedAudioUrl && (
                <div className="fc-compare position-relative">
                    <div
                        className="fc-compare-inner"
                        onDoubleClick={stopAll}
                        onClick={handleCompareClick}
                        onMouseEnter={() => {
                            setShowCompareTooltip(true);
                            setSvgCompareBackground(overColor || "#ffffff");
                        }}
                        onMouseLeave={() => {
                            setShowCompareTooltip(false);
                            setSvgCompareBackground("#ffffff");
                        }}
                    >
                        <svg
                            width="65"
                            height="65"
                            viewBox="5 5 50 50"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M19 22H44"
                                stroke={
                                    isPlaying ? overColor : svgCompareBackground
                                }
                                strokeWidth="2"
                                strokeLinecap="round"
                            />
                            <path
                                d="M41 37L18 37"
                                stroke={
                                    isPlaying ? overColor : svgCompareBackground
                                }
                                strokeWidth="2"
                                strokeLinecap="round"
                            />
                            <path
                                d="M37 15L44 22L37 29"
                                stroke={
                                    isPlaying ? overColor : svgCompareBackground
                                }
                                strokeWidth="2"
                                strokeLinecap="round"
                            />
                            <path
                                d="M24 44L17 37L24 30"
                                stroke={
                                    isPlaying ? overColor : svgCompareBackground
                                }
                                strokeWidth="2"
                                strokeLinecap="round"
                            />
                        </svg>
                    </div>
                    <Tooltip
                        visible={showCompareTooltip}
                        text="Escoltau-vos."
                    />
                </div>
            )}
            <div className="fc-micro position-relative">
                <div
                    className={`fc-micro-inner ${
                        isRecording ? "is-recording" : "is-paused"
                    }`}
                    onClick={handleClick}
                    onMouseEnter={() => {
                        setShowRecordTooltip(true);
                        setSvgBackground(overColor || "#ffffff");
                    }}
                    onMouseLeave={() => {
                        setShowRecordTooltip(false);
                        setSvgBackground("#ffffff");
                    }}
                >
                    <svg
                        width="30"
                        height="30"
                        viewBox="0 0 23 35"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M22 17.5C22 23.5751 17.299 28.5 11.5 28.5M11.5 28.5C5.70101 28.5 1 23.5751 1 17.5M11.5 28.5V34M11.5 34H16.75M11.5 34H6.25M11.5 23C8.60051 23 6.25 20.5376 6.25 17.5V6.5C6.25 3.46243 8.60051 1 11.5 1C14.3995 1 16.75 3.46243 16.75 6.5V17.5C16.75 20.5376 14.3995 23 11.5 23Z"
                            stroke={isRecording ? overColor : svgBackground}
                            strokeWidth="2"
                            strokeLinecap="round"
                        />
                    </svg>
                </div>
                <Tooltip
                    visible={showRecordTooltip || isRecording}
                    text={
                        isRecording
                            ? "<strong>Estau enregistrant.</strong> Clicau per aturar."
                            : "Clicau per enregistrar-vos. Tornau a clicar per aturar l’enregistrament."
                    }
                />
            </div>
        </>
    );
};

export default MicroComponent;
