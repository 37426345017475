import AudioComponent from "./audio";

const AudioSelectable = ({
    audio,
    index,
    selected,
    isWrong = false,
    onAudioClick = () => {},
    audioOverColor,
    overColor,
}) => {
    return (
        <div className="fc-audio-selectable-item">
            <div className="fc-audio-selectable-zone">
                <div
                    className={`fc-audio-selectable-feedback ${
                        selected ? "selected" : ""
                    } ${isWrong ? "is-wrong" : ""}`}
                    onClick={() => onAudioClick(index)}
                />
            </div>
            <AudioComponent overColor={overColor} url={audio.url} />
        </div>
    );
};

export default AudioSelectable;
